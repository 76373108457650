#location-container {
  margin-top: 10px;
  padding: 0px;
  width: 100%;
  align-self: center;
  /* border-collapse: collapse; */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(3, 8, 15, 0.09) 0px 1px 1px,
    rgba(0, 0, 0, 0.217) 0px 4px 5px, rgba(2, 1, 15, 0.09) 0px 2px 3px,
    rgba(0, 0, 0, 0.09) 0px 6px 6px;
  outline: 2px solid var(--outline-color);
}

#map-instructions {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: flex-start;
  align-items: center;
  min-width: 95%;
  max-width: 95%;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

#map-instructions-heading {
  color: var(--main-header-color);
  font-size: 22px;
  padding: 0;
  text-rendering: optimizeLegibility;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}

.instructions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.instruction {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin: 5px 0;
  padding: 0;
}

.instruction-name {
  font-size: 16px;
  font-family: var(--default-font);
  color: var(--p-yellow);
  font-weight: var(--font-weight-bold);
  margin: 0 0 0 0;
  line-height: 1;
  text-decoration: underline;
}

.map-instructions {
  color: var(--p-white);
  font-size: 14px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  padding: 2px 0;
  margin: 0;
}

#map-heading {
  align-self: center;
  color: var(--p-white);
  font-size: 18px;
  padding: 10px 0px;
  text-rendering: optimizeLegibility;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
}

#location-p {
  color: var(--p-white);
  font-size: 17px;
  font-weight: var(--default-font-weight);
  line-height: 22px;
  font-family: var(--default-font);
}

.table-row {
  background-color: var(--argus-blue-light);
  padding: 0px;
  border: 1px solid var(--outline-color);
  border-collapse: collapse !important;
}

.table-header,
.table-data {
  text-align: center;
  font-family: var(--default-font);
  padding: 5px 5px 5px 5px;
}

.table-header {
  color: var(--main-header-color);
  font-family: var(--default-font);
  text-align: center;
  font-size: 20px;
  font-weight: var(--default-font-weight);
  border: 1px solid var(--outline-color);
  border-collapse: collapse !important;
  background-color: var(--argus-blue-dark);
}

.table-data {
  color: var(--p-white) !important;
  font-family: var(--default-font) !important;
  text-align: center;
  font-size: 17px;
  font-weight: var(--default-font-weight) !important;
  padding: 5px 10px 5px 10px;
  border: 1px solid var(--outline-color) !important;
  border-collapse: collapse !important;
  background-color: var(--main-blue) !important;
}

#first-table-col {
  width: 55px;
}

#location-inputs {
  display: flex;
  align-self: center;
  flex-direction: column;
  /* width: 690px; */
  width: 100%;
}

#location-inputs p {
  color: var(--p-white);
  font-size: 17px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-shadow: none;
}

/* Datagrid */
#location-table-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 95%;
  margin-bottom: 15px;
}

.MuiDataGrid-columnHeaderDraggableContainer.location-draggable-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important;
  justify-self: center !important;
  align-self: center !important;
  margin: 0 !important;
}

.MuiDataGrid-columnHeaderDraggableContainer.location-draggable-container:focus,
.MuiDataGrid-columnHeaderDraggableContainer.location-draggable-container:focus-within,
.location-column-header:focus,
.location-column-header:focus-within {
  outline: none !important;
  border: none !important;
  cursor: default !important;
}

.location-column-headers {
  background-color: var(--argus-blue-dark);
  width: 100%;
  min-height: 55px !important;
  max-height: 55px !important;
  border-right: 1px solid rgba(27, 61, 94, 0.304) !important;
}

.location-headers {
  font-size: 19px;
  font-family: var(--default-font) !important;
  color: var(--main-header-color) !important;
  font-weight: var(--default-font-weight) !important;
  background-color: var(--argus-blue-dark) !important;
  border-right: 1px solid rgba(27, 61, 94, 0.304) !important;
  /* border-right: white !important; */
}

.location-render-zone .location-row .location-cell {
  border-right: 1px solid rgba(27, 61, 94, 0.304) !important;
  background-color: var(--main-blue) !important;
  cursor: default !important;
}

.location-render-zone .location-row:hover {
  background-color: initial !important;
}

.location-pinned-columns {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 2px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: inherit !important;
}

.location-pinned-columns .location-cell:hover {
  background-color: var(--main-blue) !important;
}

.location-pinned-columns-headers {
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--argus-blue-dark) !important;
  max-height: 55px !important;
  min-height: 55px !important;
  margin: 0 !important;
  padding: 0 !important;
  border-right: 1px solid rgba(27, 61, 94, 0.305);
}

.location-column-header {
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 55px !important;
  max-height: 55px !important;
}

.location-column-headers-inner .location-column-header {
  border-right: 1px solid rgba(27, 61, 94, 0.304) !important;
}

.pinned-column-header {
  border-right: none !important;
}

.location-column-header:focus,
.location-column-header:hover,
.location-column-header:focus-within {
  outline: none !important;
  cursor: default !important;
}

.location-column-separator {
  display: none !important;
}

.location-column-header-title-container .location-column-header-title {
  white-space: pre-wrap !important;
  line-height: normal !important;
  text-align: center !important;
  width: max-content !important;
  padding: 0 5px !important;
  font-size: 16px !important;
  text-shadow: 0px 1px 1px rgba(65, 65, 65, 0.888) !important;
  font-family: var(--default-font) !important;
  color: var(--main-header-color) !important;
  font-weight: var(--default-font-weight) !important;
  user-select: none !important;
}

.location-headers .location-column-header-title-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  max-width: fit-content !important;
}

.location-cells {
  padding: 0 !important;
  margin: 0 !important;
}

.location-cell:focus,
.location-cell:focus-within,
.location-cell:focus-visible {
  outline: none !important;
  border-bottom: 1px solid rgb(231, 231, 231);
  cursor: default !important;
}

.location-cells {
  font-family: var(--default-font) !important;
  text-shadow: 0px 1px 1px rgba(70, 70, 70, 0.5) !important;
  text-align: center !important;
  white-space: pre-wrap !important;
  font-size: 13px !important;
  font-weight: var(--default-font-weight) !important;
}

@media screen and (width < 1450px) {
  #location-container {
    width: 890px;
    align-self: center;
  }
  .table-header {
    font-size: 18px;
  }
  .location-headers {
    font-size: 16px;
  }
  .location-column-header-title-container .location-column-header-title {
    font-size: 16px !important;
  }
  .location-cells .location-cell-content {
    font-size: 14px !important;
  }
}

@media screen and (1450px <= width < 1750px) {
  #location-container {
    width: 95%;
    align-self: center;
  }
  .table-header {
    font-size: 20px;
  }
  .table-data {
    font-size: 18px;
  }
  .location-headers {
    font-size: 16px;
  }
  .location-column-header-title-container .location-column-header-title {
    font-size: 16px !important;
  }
  .location-cells .location-cell-content {
    font-size: 14px !important;
  }
}

@media screen and (1750px <= width < 2150px) {
  #location-container {
    width: 95%;
    align-self: center;
  }
  .table-header {
    font-size: 22px;
  }
  .table-data {
    font-size: 18px;
    font-weight: var(--default-font-weight) !important;
  }
}

@media screen and (2150px <= width) {
  #location-container {
    width: 1150px;
    align-self: center;
  }
  .table-header {
    font-size: 24px;
  }
  .table-data {
    font-size: 18px;
    font-weight: var(--default-font-weight) !important;
  }
  #location-table-container {
    width: 1150px;
  }
}
